import React from "react"
import { MDBContainer, MDBRow, MDBCol, MDBCarousel, MDBCarouselInner, MDBCarouselItem } from "mdbreact"

import Layout from "../components/TLCLayout"
import correction1 from "../images/services/correction/3724413.jpg"

var priceText = {
    fontWeight: '800'
}

const PaintRefinementServicesPage = () => (
    <Layout heroTitle="Services" heroImageUrl="./images/hero-dark.jpg">
        <MDBContainer style={{paddingTop: '100px', paddingBottom: '100px'}}>
            <MDBRow>
                <MDBCol>
                    <h3>Paint Refinement</h3>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol md="8">
                    <p>With over 50 year combined experience, our paint correction services truly separate TLC Auto Detail from the rest. We specialize in safely compounding and polishing your paint to remove swirls, scuffs, and light scratches while restoring clarity and gloss to the surface.</p>
                    <p>Book a free consultation today to get an estimate on getting your vehicle’s paint corrected and looking better than new.</p>
                    <p>Paint Refinement - <span style={priceText}>$75 per hour</span></p>
                </MDBCol>
                <MDBCol md="4">
                    <MDBCarousel
                        activeItem={1}
                        length={1}
                        showControls={false}
                        showIndicators={false}
                        className="z-depth-1"
                        style={{padding: 0, marginTop: '25px'}}
                    >
                        <MDBCarouselInner>
                            <MDBCarouselItem itemId="1">
                                <img className="d-block w-100" src={correction1} />
                            </MDBCarouselItem>                                                                                                                                  
                        </MDBCarouselInner>
                    </MDBCarousel>                    
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    </Layout>
)

export default PaintRefinementServicesPage